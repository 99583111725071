import styled from "styled-components";
import { width990 } from "../../responsive";

export const PageContact = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 8vh;
  ${width990({ flexDirection: "column" })}
`;

export const CardContact = styled.div`
  width: 50%;
  padding: 0 10%;
  ${width990({ width: "100%" })}
`;

export const CardInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${width990({ width: "100%", margin: "10vh 0" })}

  span {
    font-size: 14px;
    font-weight: bold;
    color: #261e1d;
    margin-bottom: 20px;
  }

  input,
  textarea {
    border-radius: 5px;
    background: #F4F4F3;
    border: 0px solid transparent;
    padding: 8px 15px;
    margin-bottom: 25px;
    resize: none;
    font-size: 12px;
    font-weight: 500;
    color: #261e1d;
  }

  input::placeholder,
  textarea::placeholder {
    color: #261e1d;
    font-weight: 500;
  }

  input:focus,
  textarea:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }

  button {
    padding: 13px 0;
    background: #f82f02;
    font-weight: bold;
    font-size: 14px;
    color: white;
    width: 100%;
    border: 0px solid transparent;
    border-radius: 5px;
    margin-top: 20px;
  }
`;

export const Titles = styled.div`
  margin-bottom: 10vh;
  line-height: 1.4;

  h1 {
    font-size: calc(1.11vw + 26.42px);
    font-weight: 100;
    color: #182b2f;
    margin: 0 !important;
    padding: 0 !important;
  }

  span {
    display: flex;
    color: #182b2f;
    font-weight: bold;
    font-size: calc(1.11vw + 26.42px);
    margin: 0 !important;
    padding: 0 !important;
  }

  span p {
    color: #f82f02;
    font-weight: bold;
    font-size: calc(1.11vw + 26.42px);
    margin: 0 !important;
    padding: 0 !important;
  }
`

export const CardImage = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${width990({ width: "100%" })}

  img {
    width: 100%;
    max-width: 800px;
    min-width: 240px;
    ${width990({ marginLeft: "0" })}
  }
`;
