import { PageAbout, Description } from "./styles";
import Papagaio from '../../assets/images/bg_papagaio.png'

export const About = () => {
  return (
    <PageAbout id="about">
      <img src={Papagaio} alt="Imagem de Fundo" />
      
      <Description>
        <h1>EarthHub</h1>
        <h2>End-to-End Solution HUB for all ESG Issues.</h2>
        <p>
          EarthHub is an end-to-end sustainability solutions laboratory powered by 
          SpA USA LLC that seeks to propose an integrated and vertical 
          solutions for negative environmental, social and climate impacts of companies, 
          organizations and projects, maximizing their economic performance while minimizing 
          the costs of implementation its sustainable solutions.
        </p>
      </Description>
    </PageAbout>
  );
};