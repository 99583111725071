export function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="170"
      height="70.756"
      viewBox="0 0 170 70.756"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="none"
            d="M0 0H170V70.756H0z"
            data-name="Rectangle 2620"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 15" transform="translate(0 .001)">
        <g
          clipPath="url(#clip-path)"
          data-name="Group 14"
          transform="translate(0 -.001)"
        >
          <path
            fill="#fafafa"
            d="M87.049 22.058c.207-.169.327-.263.445-.364a10.77 10.77 0 014.621-2.364 13.37 13.37 0 014.071-.288 13.678 13.678 0 012.674.435 8.917 8.917 0 013.56 1.858 8.666 8.666 0 012.345 3.426 13.961 13.961 0 01.848 3.654 25.654 25.654 0 01.109 2.714V48.15a3.27 3.27 0 000 .382.636.636 0 01-.253.582.525.525 0 01-.383.154h-7.532a1.653 1.653 0 00-.24 0 .694.694 0 01-.669-.369.427.427 0 01-.069-.22c-.01-.142 0-.286 0-.428V30.706a7.482 7.482 0 00-.2-1.937 5.08 5.08 0 00-.228-.676 2.833 2.833 0 00-1.748-1.646 5.14 5.14 0 00-1.061-.264 7.864 7.864 0 00-4.167.423 4.831 4.831 0 00-1.975 1.6.722.722 0 00-.162.484c.008.144 0 .286 0 .43V48.572a.656.656 0 01-.717.694h-5.339c-.764 0-1.527-.013-2.289.007a.757.757 0 01-.69-.328.6.6 0 01-.1-.256 2.912 2.912 0 01-.007-.38V8.356a1.633 1.633 0 000-.238.655.655 0 01.531-.674 1.853 1.853 0 01.332-.008h7.628a.6.6 0 01.656.638v13.349c0 .172.007.344.013.635"
            data-name="Path 34"
            transform="translate(50.846 4.853)"
          ></path>
          <path
            fill="#fafafa"
            d="M53.149 42.549a3.106 3.106 0 00-.258.175 10.387 10.387 0 01-5.246 2.319 13.979 13.979 0 01-6.854-.617 10.216 10.216 0 01-4.3-2.9 7.675 7.675 0 01-1.916-4.281 9.746 9.746 0 01.253-3.684 8.127 8.127 0 013.3-4.638 12.773 12.773 0 013.821-1.792 22.88 22.88 0 014.494-.8c1.157-.1 2.316-.106 3.474-.106h2.24c.155 0 .311-.015.441-.021.038-.06.068-.086.069-.112a7.893 7.893 0 00-.149-2.415 3.19 3.19 0 00-2.443-2.527 4.239 4.239 0 00-.607-.122 7.131 7.131 0 00-2.787.16 4.486 4.486 0 00-.919.385 2.549 2.549 0 00-1.388 2.14.785.785 0 01-.266.583.465.465 0 01-.339.145h-7.864a.634.634 0 01-.7-.747 7.432 7.432 0 01.585-2.588 9.248 9.248 0 013.2-3.888 13.906 13.906 0 014.238-2.018 19.072 19.072 0 013.977-.706 22.3 22.3 0 013.759.031 15.235 15.235 0 015.314 1.4 10.4 10.4 0 013.172 2.324 9.041 9.041 0 012.2 4.471 13.273 13.273 0 01.213 2.559c0 4.226-.008 8.453 0 12.679a15.587 15.587 0 00.417 3.636 7.856 7.856 0 00.686 1.873 1.141 1.141 0 01.137.45.706.706 0 01-.291.661.462.462 0 01-.3.119h-8.007c-.531 0-.484.074-.745-.478-.188-.4-.322-.828-.478-1.243-.05-.131-.089-.264-.142-.426m-.5-10.939a.807.807 0 00-.488-.071H49.77a12.382 12.382 0 00-1.993.136 6.963 6.963 0 00-2.175.658 3.347 3.347 0 00-1.736 1.934 4.145 4.145 0 00-.223 1.307 2.563 2.563 0 00.793 1.924 3.173 3.173 0 001.03.673 4.7 4.7 0 001.58.312 7.15 7.15 0 003.97-.954 4.843 4.843 0 001.456-1.279.968.968 0 00.208-.615c-.008-1.24 0-2.478 0-3.716a2.351 2.351 0 00-.036-.306"
            data-name="Path 35"
            transform="translate(22.526 9.424)"
          ></path>
          <path
            fill="#fa0409"
            d="M25.425 32.786a3.416 3.416 0 00.066.413 6.447 6.447 0 004.321 4.582 7.693 7.693 0 002.243.392 11.783 11.783 0 003.074-.276 8.3 8.3 0 004.347-2.507c.086-.093.174-.182.241-.253a.915.915 0 011 .314c1.241 1.317 2.506 2.613 3.76 3.919.055.056.1.119.165.193-.064.1-.119.192-.185.278a12.11 12.11 0 01-4.064 3.39 16.855 16.855 0 01-5.79 1.8 21.541 21.541 0 01-4.894.06 16.262 16.262 0 01-6.977-2.223 13.167 13.167 0 01-6.312-8.684 15.242 15.242 0 01-.372-3.45c0-.524-.012-1.05 0-1.574a16.023 16.023 0 012.583-8.6 12.941 12.941 0 015.347-4.607 15.7 15.7 0 015.586-1.453 19.878 19.878 0 013.378.028 14.189 14.189 0 015.84 1.749 11.923 11.923 0 015.18 5.907 16.816 16.816 0 01.944 3.289 18.521 18.521 0 01.311 3.6c0 .985-.008 1.97 0 2.954a.644.644 0 01-.269.57.529.529 0 01-.345.134H26.017a1.421 1.421 0 00-.6.056m10.763-6.173a5.324 5.324 0 00-.145-1.58 4.21 4.21 0 00-3.352-3.375 7.172 7.172 0 00-2.506-.127 4.822 4.822 0 00-3.63 2.259 7.319 7.319 0 00-1.015 2.55c-.018.088-.02.179-.033.291.139.015.246.036.354.038 2.674.064 9.8.031 10.327-.056"
            data-name="Path 36"
            transform="translate(10.472 9.427)"
          ></path>
          <path
            fill="#fafafa"
            d="M78.67 24.07V37.809c0 .38.053.76.086 1.139a2.321 2.321 0 00.056.279 1.543 1.543 0 001.274 1.256 6.657 6.657 0 001.94.149c.554-.041 1.106-.1 1.659-.145a.649.649 0 01.684.62V46.98a2.879 2.879 0 01-.283.111 19.324 19.324 0 01-5.886.7 12.23 12.23 0 01-3.735-.668 6.967 6.967 0 01-3.474-2.623 7.912 7.912 0 01-1.17-2.795 11.951 11.951 0 01-.279-2.6V24.17c-.121-.147-.255-.117-.379-.117h-2.812a3.516 3.516 0 00-.476 0 .845.845 0 01-.911-.481v-5.13-.24a.617.617 0 01.625-.628c1.129-.007 2.256 0 3.383 0a1.1 1.1 0 00.531-.056 8.851 8.851 0 00.035-1.324c.01-.445 0-.889 0-1.334v-3.949c.031-.051.055-.093.081-.131a.8.8 0 01.736-.331c2.509.012 5.02.008 7.53 0a.726.726 0 01.635.24.537.537 0 01.147.385c-.008.111 0 .223 0 .334v5.577c0 .172.01.344.013.5a.828.828 0 00.54.086h4.051c.567.007.788.231.79.815v4.959a.672.672 0 01-.717.7h-4.1c-.172 0-.344.01-.579.017"
            data-name="Path 37"
            transform="translate(42.413 6.823)"
          ></path>
          <path
            fill="#fafafa"
            d="M62.286 14.99c.086.859.1 1.679.2 2.544a1.4 1.4 0 00.185-.126c.2-.207.383-.423.587-.625a7.87 7.87 0 014.761-2.283 9.746 9.746 0 013.821.3c.091.025.177.064.281.1.01.1.025.19.025.281q-.045 3.05-.094 6.1c-.007.365-.012.731-.025 1.1a.64.64 0 01-.659.6c-.491-.038-.98-.1-1.471-.136a12.105 12.105 0 00-3.835.235 5.891 5.891 0 00-1.835.774 4.362 4.362 0 00-1.283 1.326.968.968 0 00-.165.583c.008.144 0 .286 0 .43v17.4a3.678 3.678 0 000 .382.632.632 0 01-.258.58.488.488 0 01-.342.139H54.316a.685.685 0 01-.7-.681V15.98a1.632 1.632 0 000-.238.656.656 0 01.3-.653.624.624 0 01.253-.107 2.021 2.021 0 01.332-.007h7.246c.159 0 .316.008.536.015"
            data-name="Path 38"
            transform="translate(34.999 9.424)"
          ></path>
          <path
            fill="#fafafa"
            d="M9.153 13.186c.641-.051 7.983-.036 8.352.015a1.545 1.545 0 01.046.221c.008.142 0 .286 0 .428v33.608a1.271 1.271 0 01-.071.669H9.175a2.039 2.039 0 01-.053-.269c-.01-.127 0-.255 0-.382V13.822a2.552 2.552 0 01.035-.636"
            data-name="Path 39"
            transform="translate(5.951 8.588)"
          ></path>
        </g>
      </g>
    </svg>
  );
}
