import styled from "styled-components";
import { width700, width990 } from "../../responsive";

export const PageDimensions = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10%;
`;

export const Top = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  ${width990({ flexDirection: "column" })}
`;

export const CardImage = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;
  top: 8%;
  ${width990({
    width: "100%",
    alignItems: "center",
    position: "relative",
    top: "0",
  })}

  img {
    width: 100%;
    max-width: 800px;
    min-width: 240px;
    margin-left: -20%;
    margin-top: 10%;
    ${width990({ margin: "40px 0" })}
  }

  h1 {
    font-size: calc(1.11vw + 16.42px);
    font-weight: bold;
    color: #182b2f;
  }

  h2 {
    font-size: calc(1.11vw + 16.42px);
    font-weight: 200;
    color: #182b2f;
  }
`;

export const Description = styled.div`
  width: 50%;
  padding-right: 16%;
  ${width990({
    width: "100%",
    paddingRight: "0",
  })}
`;

export const Info = styled.div`
  margin-bottom: 80px;

  p {
    font-size: calc(0.2232142857142857vw + 13.285714285714286px);
    font-weight: 300;
    color: #182b2f;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  span {
    color: #fa0409;
    font-weight: 500;
  }
`;

export const Title = styled.h3`
  font-size: calc(1.11vw + 16.42px);
  font-weight: 200;
  color: #182b2f;
  margin-bottom: 25px;
  line-height: 1.3;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30%;
  ${width700({ flexDirection: "column", marginTop: "0" })}

  img {
    flex: 1;
    width: 20vw;
    max-width: 300px;
    min-width: 100px;
    margin: 0 30px;
  }
`;

export const Text = styled.span`
  flex: 1;
  font-size: calc(3.44vw + 0.86px);
  ${width700({ fontSize: "calc(1.11vw + 21.42px)" })}
  font-weight: 200;
  color: #182b2f;
  text-align: ${({ position }) => position === "left" && "right"};
  text-align: ${({ position }) => position === "right" && "left"};
`;
