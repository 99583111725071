import React, { useEffect, useState } from 'react';
import './style.css'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarLink,
    MDBCollapse,
    MDBNavbarBrand
} from 'mdb-react-ui-kit';
import { Logo } from "../../assets/images/Logo";

export const Navbar = () => {

    const [showNavSecond, setShowNavSecond] = useState(false);

    useEffect(() => {
        document.getElementById('Navbar').getElementsByClassName('navbar-toggler').item(0).addEventListener('click', function (event) {
            let nav = document.getElementById('Navbar')
            nav.classList.toggle('bg-color')
        });
    }, []);

    return (

        <MDBNavbar expand='lg' light id="Navbar">
            <MDBContainer fluid>
                <MDBNavbarBrand href='#'>
                    <Logo/>
                </MDBNavbarBrand>
                <MDBNavbarToggler
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={() => setShowNavSecond(!showNavSecond)}
                >
                    <svg height="48" width="48" fill="#fff"><path d="M5.15 37.4V32.7H42.85V37.4ZM5.15 26.35V21.65H42.85V26.35ZM5.15 15.3V10.55H42.85V15.3Z" /></svg>
                </MDBNavbarToggler>
                <MDBCollapse navbar show={showNavSecond}>
                    <MDBNavbarNav>
                        <MDBNavbarLink href="#about">About</MDBNavbarLink>
                        <MDBNavbarLink href="#dimensions">Dimensions</MDBNavbarLink>
                        <MDBNavbarLink href="#why">Why EarthHub</MDBNavbarLink>
                        <MDBNavbarLink href="#process">Process</MDBNavbarLink>
                        <MDBNavbarLink href="#projects">Projects</MDBNavbarLink>
                        <MDBNavbarLink href="#contact">Contact</MDBNavbarLink>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    );
};