import { PageProjects, Info, Left, Right, Line, Top, Bottom, Mobile } from "./styles";
import VideoBoi from '../../assets/video/boi.mp4'
import Campos from '../../assets/images/campos.png';
import PorDoSol from '../../assets/images/por-do-sol.png';
import Rebanho from '../../assets/images/rebanho.png';
import Rebanho2 from '../../assets/images/rebanho2.png';
import Gados from '../../assets/images/gados.png'

export const Projects = () => {

  return (
    <PageProjects id="projects">

      {/* Mobile */}
      <Mobile>
        <Top>
          <p>Projeto</p>
          <h1>Captar <br /> Agro</h1>
        </Top>

        <Bottom>
          <p>Captar Agrobusiness promotes social and environmental impacts through projects that enable and
            encourage the agricultural sector to adhere to the Low Carbon Circular Economy. With proprietary
            solutions and models, we bring to the sector what is most modern in the market to promote projects
            related to sustainability in Brazil and abroad. Together with Captar Agrobusiness, we have developed
            an innovative model of Sustainable Livestock that seeks to be the benchmark in the global livestock industry.
            Based on a proprietary methodology and supported by SustainAlytics that proves it's effectiveness,
            the project places Captar Agrobusiness in a prominent position as a reference in Brazil and abroad.
          </p>

          <button>More</button>
        </Bottom>
        <video muted loop autoPlay="autoplay">
          <source src={VideoBoi} type="video/mp4"></source>
        </video>
      </Mobile>

      {/* WEB */}
      <Left>
        <Info>
          <Top>
            <p>Projeto</p>
            <h1>Captar <br /> Agro</h1>
          </Top>

          <Bottom>
            <p>Captar Agrobusiness promotes social and environmental impacts through projects that enable and
              encourage the agricultural sector to adhere to the Low Carbon Circular Economy. With proprietary
              solutions and models, we bring to the sector what is most modern in the market to promote projects
              related to sustainability in Brazil and abroad. Together with Captar Agrobusiness, we have developed
              an innovative model of Sustainable Livestock that seeks to be the benchmark in the global livestock industry.
              Based on a proprietary methodology and supported by SustainAlytics that proves it's effectiveness,
              the project places Captar Agrobusiness in a prominent position as a reference in Brazil and abroad.
            </p>

            <button>More</button>
          </Bottom>

        </Info>
        <video muted loop autoPlay="autoplay">
          <source src={VideoBoi} type="video/mp4"></source>
        </video>
      </Left>

      <Right>
        <Line>
          <img src={Rebanho2} alt="Imagem" />
          <img src={Rebanho} alt="Imagem" />
        </Line>

        <Line>
          <img src={Campos} alt="Imagem" />
        </Line>

        <Line>
          <img src={PorDoSol} alt="Imagem" />
          <img src={Gados} alt="Imagem" />
        </Line>
      </Right>

    </PageProjects>
  );
};
