import "react-responsive-carousel/lib/styles/carousel.min.css";
import './carrosel.css';

import { Carousel } from 'react-responsive-carousel';

import bg_Indio from "../../assets/images/bg_indio.png";
import bg_Rio from "../../assets/images/bg_rio.png";
import bg_Guarana from "../../assets/images/bg_guarana.png";
import bg_MicoLeao from "../../assets/images/bg_micoLeao.png";
import bg_Papagaio from "../../assets/images/bg_pagagaio2.png";

import Hands from "../../assets/icons/hands.svg";
import Plant from "../../assets/icons/plant.svg";
import PenPaper from "../../assets/icons/penPaper.svg";
import PlantLabel from "../../assets/icons/plantLabel.svg";
import Chart from "../../assets/icons/chart.svg";

export default function CarouselComponent() {
    return (
        // https://www.positronx.io/react-responsive-carousel-tutorial/
        <div className="carousel-wrapper">
            <Carousel infiniteLoop swipeable showThumbs={false} showArrows showStatus={false}>
                <div className="item">
                    <img src={bg_Indio} alt="Indio" />
                    <div className="description">
                        <h1>More Trustability</h1>
                        <span>An integrated process with world-renowned partners such as Grant Thornton and others Earth partners provides additional assurance and credibility.</span>
                        <img src={Hands} alt="Hands" />
                    </div>
                </div>
                <div className="item">
                    <img src={bg_Rio} alt="Rio" />
                    <div className="description">
                        <h1>Ratify Sustainability</h1>
                        <span>With all steps completed and assured, Project owners and issuers can officially report their sustainable assets and objectives to investors and other stakeholders.</span>
                        <img src={Plant} alt="Plant" />
                    </div>
                </div>
                <div className="item">
                    <img src={bg_Guarana} alt="Guarana" />
                    <div className="description">
                        <h1>Framework Support</h1>
                        <span>Our Earthlytics assesses the sustainable design's alignment with the chosen framework as recognized by International Capital Market Association (ICMA).</span>
                        <img src={PenPaper} alt="PenPaper" />
                    </div>
                </div>
                <div className="item">
                    <img src={bg_MicoLeao} alt="Mico Leão" />
                    <div className="description">
                        <h1>Qualified Issuance</h1>
                        <span>With all steps completed and assured, Project owners and issuers can officially report their sustainable assets and objectives to investors and other stakeholders.</span>
                        <img src={PlantLabel} alt="PlantLabel" />
                    </div>
                </div>
                <div className="item">
                    <img src={bg_Papagaio} alt="Papagaio" />
                    <div className="description">
                        <h1>Process, KPIs & SPT.s allignment</h1>
                        <span>An integrated process with world-renowned partners such as Grant Thornton and others Earth partners provides additional assurance and credibility.</span>
                        <img src={Chart} alt="Chart" />
                    </div>
                </div>
            </Carousel>
        </div>
    );
}