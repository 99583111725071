import styled from "styled-components";
import { width600, width990 } from "../../responsive";

export const PageAbout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    width: 100%;
    height: 100%;
    min-height: 80vh !important;
    object-fit: cover;
  }
`;

export const Description = styled.div`
  position: absolute;
  width: 55%;
  color: #ffffff;
  margin-top: 300px;
  padding: 0px 10%;
  line-height: 1.3;
  ${width990({ width: "70%" })}
  ${width600({ width: "100%" })}

  h1 {
    font-size: calc(2.23vw + 12.85px);
    font-weight: bold;
  }

  h2 {
    font-size: calc(2.23vw + 12.85px);
    font-weight: 200;
    margin: 20px 0;
    text-shadow: 0px 1px 1px #000000;
  }

  p {
    font-size: calc(0.4464285714285714vw + 12.571428571428571px);
    font-weight: 200;
    text-shadow: 1px 1px 1px #000000;
  }
`;
