import { css } from "styled-components";

export const width990 = (props) => {
    return css`
        @media only screen and (max-width: 990px) {
            ${props}
        }
    `
} 

export const width700 = (props) => {
    return css`
        @media only screen and (max-width: 700px) {
            ${props}
        }
    `
} 

export const width600 = (props) => {
    return css`
        @media only screen and (max-width: 600px) {
            ${props}
        }
    `
} 