import { PageContact, CardImage, CardInput, CardContact, Titles } from "./styles";
import img from "../../assets/images/globo.png"
import api from '../../services/api'
import { useState } from "react";
import { useSnackbar } from 'react-simple-snackbar'

export const Contact = () => {

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const [openSnackbar] = useSnackbar({
    style: {
      backgroundColor: 'white',
      color: 'black',
      zIndex: 9999
    },
    closeStyle: {
      color: 'black',
    },
  })

  const createContact = () => {
    if(email === "" || message === "") {
      openSnackbar('Por favor, preencha todos os campos.', [8000])
      return
    }
    else {
      let content = new FormData();

      content.append("email", email);
      content.append("message", message);
  
      const config = {
        headers: { "content-type": "multipart/json" },
      };
  
      api
        .post("core/contact/", content, config)
        .then((response) => {
          openSnackbar('Contato enviado com sucesso!', [15000])
          setEmail("")
          setMessage("")
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <PageContact id="contact">

      <CardContact>
        <CardInput>
          <Titles>
            <h1>We feel</h1>
            <span>Thank You <p>.</p></span>
            <h1>See you soon</h1>
          </Titles>

          <span>Email</span>
          <input
            value={email}
            type="text"
            placeholder="Email"
            onChange={e => setEmail(e.target.value)}
          />

          <span>Message</span>
          <textarea
            value={message}
            name="textarea"
            placeholder="Message"
            rows="5"
            cols="50"
            onChange={e => setMessage(e.target.value)}
          >
          </textarea>

          <button onClick={createContact}>
            Send
          </button>
        </CardInput>
      </CardContact>

      <CardImage>
        <img src={img} alt="Globo verde" />
      </CardImage>
    </PageContact>
  );
};
