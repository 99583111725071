import styled from "styled-components";
import { width990 } from "../../responsive";

export const PageCertifications = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10%;
  ${width990({ flexDirection: "column" })}
`;

export const CardImage = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${width990({ width: "100%", alignItems: "center" })}

  img {
    width: 100%;
    max-width: 800px;
    min-width: 240px;
    margin-left: -15%;
    ${width990({ marginLeft: "0" })}
  }
`;

export const Description = styled.div`
  width: 50%;
  ${width990({
    width: "100%",
  })}

  h1 {
    font-size: calc(1.11vw + 11.42px);
    font-weight: 100;
    color: #182b2f;
    line-height: 1.5;
  }

  span {
    font-size: calc(1.11vw + 11.42px);
    font-weight: 700;
    color: #182b2f;
  }
`;
