import logo_black from '../../assets/images/logo_black.png'
import { CardLogo, Content, Info, LineBottom, LineTop, Links } from './styles';

export function Footer() {
  return (
    <Content>
      <LineTop>
        <CardLogo>
          <img src={logo_black} alt="Logo black" />

          <Info>
            <span>Made in Florida</span>
            <p>SpA USA LLC.</p>
          </Info>
        </CardLogo>

        <Links>
          <a href="#about">About</a>
          <a href="#dimensions">Dimensions</a>
          <a href="#why">Why EarthHub</a>
          <a href="#process">Process</a>
          <a href="#projects">Projects</a>
          <a href="#contact">Contact</a>
        </Links>
      </LineTop>

      <LineBottom>
        ®SpA USA LLC | Earth is a brand of SpA USA LLC. All rights reserved.
      </LineBottom>
    </Content>
  );
}