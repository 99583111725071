import styled from "styled-components";
import { width700, width990 } from "../../responsive";

export const PageProcess = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 8% 10%;
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 8%;
  margin-top: 4%;
  ${width990({ flexDirection: "column" })}

  h1 {
    font-size: calc(1.11vw + 16.42px);
    font-weight: bold;
    color: #182b2f;
  }

  span {
    font-size: calc(1.11vw + 16.42px);
    font-weight: 200;
    color: #182b2f;
  }
`;

export const Description = styled.div`
  margin-bottom: 6%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  h2 {
    font-size: calc(2.232142857142857vw + 22.857142857142858px);
    font-weight: bold;
    color: #182b2f;
    margin-bottom: 25px;
  }

  span {
    font-size: calc(2.232142857142857vw + 22.857142857142858px);
    font-weight: bold;
    color: #fa0409;
  }
`;

export const Number = styled.div`
  width: 20%;
  display: flex;
  align-items: baseline;
  ${width990({ width: "15%" })}
`;

export const Info = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${width990({ width: "85%" })}

  h3 {
    font-weight: bold;
    font-size: calc(1.11vw + 16.42px);
    color: #182b2f;
    margin-bottom: 25px;
  }

  p {
    font-size: calc(0.2232142857142857vw + 13.285714285714286px);
    font-weight: 300;
    color: #182b2f;
    margin-bottom: 10px;
    line-height: 1.5;
    padding-right: 5%;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${width990({ flexDirection: "column", marginTop: "0" })}
  ${width700({ flexDirection: "column" })}
`;

export const Left = styled.div`
  flex: 1;
`;

export const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const CardImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 700px;
    min-width: 240px;
    ${width990({ marginRight: "0" })}
  }
`;
