import styled from "styled-components";
import { width600, width990 } from "../../responsive";

export const Content = styled.div`
  width: 100%;
  border-top: 4px solid #f90308;
  padding: 4% 10%;
  ${width990({ padding: "8% 10%" })}
  ${width600({ padding: "10% 10%" })}
`;

export const LineTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10%;
`;

export const CardLogo = styled.div`
  width: 165px;
  display: flex;
  flex-direction: column;

  img {
    width: 100px;
  }
`;

export const Links = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  gap: 3px;
  ${width990({ flexDirection: "column", alignItens: "flexEnd" })}

  a {
    font-size: calc(0.22vw + 11.28px);
    color: #182b2f;
    font-weight: 100;
  }
`;

export const Info = styled.div`
  margin-top: 40px;

  span {
    font-size: calc(0.22vw + 11.28px);
    font-weight: 300;
    color: #182b2f;
  }

  p {
    font-size: calc(0.22vw + 11.28px);
    font-weight: bold;
    color: #182b2f;
  }
`;

export const LineBottom = styled.span`
  width: 100%;
  display: flex;
  font-size: calc(0.22vw + 11.28px);
  font-weight: 100;
  justify-content: flex-start;
  ${width990({ justifyContent: "center" })}
`;
