import { PageWhy, CardImagem, Initial, CardOption, Description, Web, Mobile, Return } from "./styles";
import { useState } from "react";

// Imagens iniciais
import Indio from "../../assets/images/Indio.png"
import Rio from "../../assets/images/Rio.png"
import Guarana from "../../assets/images/Guarana.png"
import MicoLeao from "../../assets/images/Mico_Leao.png"
import Papagaio from "../../assets/images/Papagaio.png"

// Background
import bg_Indio from "../../assets/images/bg_indio.png"
import bg_Rio from "../../assets/images/bg_rio.png"
import bg_Guarana from "../../assets/images/bg_guarana.png"
import bg_MicoLeao from "../../assets/images/bg_micoLeao.png"
import bg_Papagaio from "../../assets/images/bg_pagagaio2.png"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselComponent from "./carrosel";

// Icons
import Arrow from "../../assets/icons/arrow.png";
import Hands from "../../assets/icons/hands.svg";
import Plant from "../../assets/icons/plant.svg";
import PenPaper from "../../assets/icons/penPaper.svg";
import PlantLabel from "../../assets/icons/plantLabel.svg";
import Chart from "../../assets/icons/chart.svg";

const InfoOption = [
  {
    background_card: bg_Indio,
    title: "More Trustability",
    description: "An integrated process with world-renowned partners such as Grant Thornton and others Earth partners provides additional assurance and credibility.",
    icon: Hands,
  },
  {
    background_card: bg_Rio,
    title: "Ratify Sustainability",
    description: "With all steps completed and assured, Project owners and issuers can officially report their sustainable assets and objectives to investors and other stakeholders.",
    icon: Plant,
  },
  {
    background_card: bg_Guarana,
    title: "Framework Support",
    description: "Our Earthlytics assesses the sustainable design's alignment with the chosen framework as recognized by International Capital Market Association (ICMA).",
    icon: PenPaper,
  },
  {
    background_card: bg_MicoLeao,
    title: "Qualified Issuance",
    description: "With all steps completed and assured, Project owners and issuers can officially report their sustainable assets and objectives to investors and other stakeholders.",
    icon: PlantLabel,
  },
  {
    background_card: bg_Papagaio,
    title: "Process, KPIs & SPT.s allignment",
    description: "An integrated process with world-renowned partners such as Grant Thornton and others Earth partners provides additional assurance and credibility.",
    icon: Chart,
  }
];


export const WhyEarthHub = () => {

  const [isView, setIsView] = useState();
  const [activeOption, setActiveOption] = useState();

  function setPosition(index) {

    if (index === null) {
      setActiveOption(null)
      setIsView(false)
    }
    else {
      setActiveOption(index)
      setIsView(true)
    }
  }

  return (
    <PageWhy id="why">

      <Web>
        <Initial>
          <CardImagem onClick={() => setPosition(0)}>
            <img src={Indio} alt="Indio" />
            <span>More <br /> Trustability</span>
          </CardImagem>

          <CardImagem onClick={() => setPosition(1)}>
            <img src={Rio} alt="Rio" />
            <span>Ratify <br /> Sustainability</span>
          </CardImagem>

          <CardImagem onClick={() => setPosition(2)}>
            <img src={Guarana} alt="Guarana" />
            <span>Framework <br /> Support</span>
          </CardImagem>

          <CardImagem onClick={() => setPosition(3)}>
            <img src={MicoLeao} alt="MicoLeao" />
            <span>Process, KPIs <br /> & SPT.s <br /> allignment</span>
          </CardImagem>

          <CardImagem onClick={() => setPosition(4)}>
            <img src={Papagaio} alt="Papagaio" />
            <span>Qualified <br /> Issuance</span>
          </CardImagem>
        </Initial>

        {
          isView === true &&
          <CardOption>
            <img src={InfoOption[activeOption].background_card} alt="Indio" />
            <Description>
              <Return onClick={() => setPosition(null)}>
                <img src={Arrow} alt="Arrow" />
              </Return>
              <h1> {InfoOption[activeOption].title} </h1>
              <span> {InfoOption[activeOption].description} </span>
              <img id="icon" src={InfoOption[activeOption].icon} alt="Ícone" />
            </Description>
          </CardOption>
        }
      </Web>

      <Mobile>
        <CarouselComponent />
      </Mobile>

    </PageWhy>
  );
};