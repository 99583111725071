import { PageCertifications, CardImage, Description } from "./styles";
import img from "../../assets/images/Image 19@2x.png"

export const Certifications = () => {
  return (
    <PageCertifications>

      <CardImage>
        <img src={img} alt="Imagem" />
      </CardImage>

      <Description>
        <h1>“Environmental certifications don't just help you show what you're doing right, but ratify the actions.
          <span> Certifications put your policies in the spotlight and allowan in-depth analysis of process and practices.”</span></h1>
      </Description>

    </PageCertifications>
  );
};