import { PageDimensions, CardImage, Description, Info, Top, Bottom, Text, Title } from "./styles";
import img from "../../assets/images/about_img.png"
import forest from '../../assets/images/eForest copiar@2x.png'

const descriptions = [
  { id: 1, title1: 'Advisory', title2: 'Engagement', text: 'Our sustainability goals will be communicated clearly and transparently to our audience and we will use our position and reach to interact with our customers to raise awareness of environmental issues.' },
  { id: 2, title1: 'Project', title2: 'Design', text: 'Solutions for Sustainable Accounting and report issuance based on GIR, IIRC, ISSB, IFRS and major regulatory bodies and global frameworks.' },
  { id: 3, title1: 'Earthlytics', title2: 'Report', text: 'With our proprietary calculation tools, EarthCalculus, a carbon footprint calculator and EarthEDSIndex, a customized framework index of adherence, we will base all our studies and proposals providing the best security for the system.' },
  { id: 4, title1: 'Assurance', title2: 'Certification', text: 'Solution for Pre-Verification, Verification, SPOs and Sustainability Certification for companies and projects in partnership with the large six audit campanies.' },
  { id: 5, title1: 'Carbon', title2: 'Trade Desk', text: 'Solution for Certified Carbon Credits trade via a proprietary Trade Desk and advisory to bond issuance of SSBs, SLBs, STSBs and Premium Carbon Credits' },
  { id: 6, title1: 'REDD+', title2: '', text: 'We develop REDD+ projects. REDD+ was born at Convention on Change UN Climate Change, establishing criteria for remunerating countries that reduce emissions caused by deforestation. Reducing Emissions from Deforestation and Forest Degradation REDD is where Brazil has the greatest potential to develop, given the profile of its greenhouse gas emissions, largely associated with deforestation and changes in land use.' },
  { id: 7, title1: 'Sustainable', title2: 'Finance', text: 'We provide all sorts of services and solutions at Sustainable Finance. Advisory, Project Desing, Sustainable Bond Issuance, Certified Carbon Credir Issuance, Sustainable Finance Area Structuring and many others solutions we can help you and your company.' },
]

export const Dimensions = () => {
  return (
      <PageDimensions id="dimensions">
        
        <Top>
          <CardImage>
            <h1>EarthHub</h1> 
            <h2>&amp;	its Dimensions.</h2>
            <img src={img} alt="Imagem" />
          </CardImage>

          <Description>
          {
            descriptions.map((item => (
              <Info key={item.id}>
                {
                  item.title2 === '' &&
                <Title>{item.title1}<span>.</span></Title>
                }
                {
                  item.title2 !== '' &&
                  <Title>{item.title1}<br/>{item.title2}<span>.</span></Title>
                }
                  <p> {item.text} </p>
             </Info>
            )))
          }
          </Description>
        </Top>
        
        <Bottom>
          <Text position="left">We have the</Text>
          <img src={forest} alt="Imagem Floresta" />
          <Text position="right">solution.</Text>
        </Bottom>
        
      </PageDimensions>
  );
};