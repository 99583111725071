import { PageProcess, Description, Info, Right, Top, Bottom, Number, CardImage, Left } from "./styles";
import img from "../../assets/images/why_img.png"

const descriptions = [
  { number: 1, title: 'Thesis ESG.', text: 'In-depth study of the ESG Thesis presented to determine if there is a foundation and sustainability with the principles of the main sustainable frameworks in the market.' },
  { number: 2, title: 'Process Analisys.', text: "Analysis to understand the company's internal processes to ensure that the ESG Thesis adheres to the proposed taxonomy. Point out the relevant KPIs via Project Design, with due materiality, proposing a methodology consistent with the proposed Sustainability Performance Goals (SPTs)." },
  { number: 3, title: 'Material Analisys.', text: 'Statistical diagnosis based on KPIs and SPTs to ratify the defined strategic proposal. Calibrate what is necessary through diagnosis for disclosure of sustainable title with the proposed characteristics comply with the proposed framework.' },
  { number: 4, title: 'Report.', text: 'Issuing the Report in a transparent, reliable and adhering to the proposed framework and SPTs. Verification of the formatting of information on the content elements and the basic principles established in the guidelines of the Framework used (ISSB or IIRC) with adherence to the guidelines and criteria of the reporting structure in the standards that currently exist in the market (i.e. GRI, SASB, CDP , TCFD etc.) Propose monitoring of the performance of KPIs, through independent monitoring (Post SPO).' },
]

export const Process = () => {

  return (
    <PageProcess id="process">

      <Top>
        <h1>Overview Sustainable SPO Earth|Partner.</h1>
        <span>Assuring Sustainability</span>
      </Top>

      <Bottom>
        <Left>
          {
            descriptions.map((item) => (
              <Description key={item.number}>
                <Number>
                  <h2>{item.number}</h2>
                  <span>.</span>
                </Number>
                 <Info>
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                </Info>
              </Description>
            ))
          }
        </Left>

        <Right>
        <CardImage>
            <img src={img} alt="Imagem" />
        </CardImage>
        </Right>
      </Bottom>


    </PageProcess>
  );
};