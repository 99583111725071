import styled from "styled-components";
import { width990 } from "../../responsive";

export const PageProjects = styled.div`
  background-color: #202d22;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 8% 10% 12%;
  ${width990({ flexDirection: "column" })}
`;

export const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: calc(43.68vw + 0.5px);
  position: absolute;
  background-color: #2194d3;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.8s ease;
  cursor: initial;
`;

export const Top = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  padding: 6% 20% 4% 10%;

  p {
    font-size: calc(1.1160714285714286vw + 11.428571428571429px);
    font-weight: 100;
    color: #ffffff;
    margin: 0;
  }

  h1 {
    font-size: calc(4.464285714285714vw + 25.714285714285715px);

    font-weight: bold;
    color: #ffffff;
    line-height: 1;
    z-index: 15;
    margin: 12px 0;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 20% 4% 10%;

  p {
    font-size: calc(0.22vw + 9.28px);
    color: #fafafa;
    margin-bottom: 2rem;
    max-height: 350px;
    overflow: auto;
    line-height: 1.8;
    ${width990({ maxHeight: "100%", overflow: "hidden" })}
    
    /* Largura da barra de rolagem */
    ::-webkit-scrollbar {
      width: 0px;
    }
  }

  button {
    background-color: #202d22;
    width: 50%;
    max-width: 115px;
    border-radius: 10px;
    border: 0px solid transparent;
    padding: 15px 30px;
    color: #fafafa;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const Left = styled.div`
  width: 50%;
  position: relative;
  z-index: 2;
  cursor: pointer;
  ${width990({ display: "none" })}

  video {
    width: 100%;
    height: calc(43.68vw + 0.5px);
    object-fit: cover;
  }

  &:hover ${Info} {
    opacity: 1;
  }
`;

export const Right = styled.div`
  width: 50%;
  ${width990({ width: "100%" })}

  img {
    width: 50%;
    object-fit: cover;
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  img {
    flex: 1;
  }
`;

export const Mobile = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  ${width990({ display: "flex" })}
  background-color: #2194d3;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  video {
    width: 100%;
  }
`;
