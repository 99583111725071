import "./global.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { Navbar } from "./components/navbar/index";
import { About } from "./components/about";
import { Dimensions } from "./components/dimensions";
import { WhyEarthHub } from "./components/why";
import { Process } from "./components/process";
import { Projects } from "./components/projects";
import { Certifications } from "./components/certifications";
import { Footer } from "./components/footer";
import { Contact } from "./components/contact";
import SnackbarProvider from 'react-simple-snackbar'

export const Home = () => {
  return (
    <SnackbarProvider>
      <div>
        <Navbar />
        <About />
        <div className="main-content">
          <Dimensions />
          <WhyEarthHub />
          <Process />
          <Projects />
          <Certifications/>
          <Contact/>
          <Footer />
        </div>
      </div>
    </SnackbarProvider>
  );
};