import styled from "styled-components";
import { width700, width990 } from "../../responsive";

export const PageWhy = styled.div`
  position: relative;
`;

export const Initial = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
`;

export const Web = styled.div`
  display: flex;
  ${width700({ display: "none" })}
`;

export const Mobile = styled.div`
  width: 100vw;
  display: none;
  ${width700({ display: "flex" })}
`;

export const CardImagem = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    object-fit: cover;
  }

  span {
    position: absolute;
    padding-left: 10%;
    font-size: calc(0.89vw + 5.14px);
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0px 1px 1px #000000;
    padding-bottom: 20%;
    line-height: 1.2;
  }
`;

export const CardOption = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  transition: all 0.8s ease;
  animation: moveRight linear 0.5s;
  ${width700({ animation: "none" })}

  img {
    width: 60%;
    height: 100%;
    max-height: 100vh;
    object-fit: cover;
  }

  @keyframes moveRight {
    0% {
      left: -100%;
    }
    100% {
      left: 0;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 8%;

  h1 {
    font-size: calc(0.89vw + 13.14px);
    font-weight: 100;
    color: #202d22;
    margin: 10% 0;
  }

  span {
    font-size: calc(0.2232142857142857vw + 13.285714285714286px);
    font-weight: 300;
    color: #202d22;
    margin-bottom: 50px;
  }

  img#icon {
    width: 6vw;
    max-width: 100px;
  }
`;

export const Return = styled.div`

  img {
    cursor: pointer;
    width: 30px;
    ${width990({ width: "20px" })}
  }
`;
